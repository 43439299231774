<template>
  <div class="column pt-0">
    <h2 class="is-size-5 has-text-weight-semibold mb-4">Список пользователей</h2>
    <BaseTable
      :data="users"
      :columns="columns"
      :loading="isLoading"
      :total="total"
      :per-page="perPage"
      :current-page="currentPage"
      @change="fetchUsers"
      @rowClick="onRowClick"
    />
  </div>
</template>


<script>
import BaseTable from "@/components/Base/Table";

export default {
  components: { BaseTable },
  data() {
    return {
      users: [],
      total: 0,
      isLoading: true,
      currentPage: 1,
      perPage: 10,
      columns: [
        {
          field: "id",
          label: "ID",
          width: "40",
          numeric: true,
        },
        {
          field: "last_name",
          label: "Фамилия",
        },
        {
          field: "first_name",
          label: "Имя",
        },
        {
          field: "email",
          label: "Email",
          centered: true,
        },
        {
          field: "phone",
          label: "Номер телефона",
        },
        {
          field: "branch.company.name",
          label: "Организация",
        },
        {
          field: "role.name",
          label: "Роль пользователя",
        },
      ],
    };
  },
  mounted() {
    this.fetchUsers();
  },
  methods: {
    async fetchUsers(page) {
      this.currentPage = page || 1
      this.isLoading = true;
      try {
        const { data } = await this.$axios.get(`users/paginate/${(this.currentPage - 1) * 10}/${this.perPage}`)
        this.users = data?.data || [];
        this.total = data?.count;
      } catch (e) {
        throw new Error(e)
      }
      this.isLoading = false;
    },
    onRowClick(item) {
      this.$router.push(`/users-editor/${item.id}`);
    },
  },
};
</script>


<style>

</style>
